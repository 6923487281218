@import "style/variables.module";

.WriteToUs {
  max-width: 960px;
  margin-right: 350px;

  .write-to-us-content {
    background-color: $dirtyWhite;

    .sub-page {
      padding: 10px;
    }

    .contact-form {
      padding: 45px;
    }

    h5 {
      margin: 0;
      padding: 0;
      font-size: 16px;
      text-align: justify;
    }

    p {
      text-align: justify;
      font-size: 16px;
      line-height: 24px;
    }

    .form-mandatory-text {
      margin-bottom: 15px;
    }
  }

  .h4-elements {
    font-weight: bold;
    font-size: 18px;
  }

  .wrap-line {
    display: inline-block;
    width: 49%;
    box-sizing: border-box;
  }
}

@media (max-width: 1024px) {
  .WriteToUs {
    margin-right: 0;
  }
}
