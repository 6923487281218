@import "src/mixins.scss";
@import "src/style/variables.module";

.Partners {
  max-width: 960px;
  margin-right: 350px;

  .content {
    padding: 0 48px;
  }

  table {
    border: 1px solid;
  }

  .orange {
    span {
      color: $primary-orange;
    }
    table {
      border-color: $primary-orange;
    }
  }

  .green {
    span {
      color: $primary-green;
    }
    table {
      border-color: $primary-green;
     }
  }
  .purple {
    span {
      color: $primary-purple;
    }
    table {
      border-color: $primary-purple;
    }
  }

  table tr {
    border: none;
    border-bottom: 1px solid #fff;
  }
  table tr td {
    border: none;
  }
  table tr td:first-of-type {
    width: 30px;
    text-align: center;
  }

  table img {
    max-width: 200px;
    height: auto !important;
  }

  .banner {
    @include banner;
  }
}

@media (max-width: 1024px) {
  .Partners {
    margin-right: 0;
  }
  .banner {
    display: none;
  }
}
