@import "style/variables.module";
@import "src/mixins.scss";

.FAQTemplate {
  max-width: 960px;
  margin-right: 350px;

  .upper {
    .MuiPaper-root {
      padding-left: 10px;
    }
    .Mui-expanded {
      min-height: unset;
      padding-top: 4px;
    }
    .MuiButtonBase-root {
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
  }

  .lower {
    .lower-inner {
      margin: 10px 0;
      padding: 10px 30px 30px 30px;
      background-color: $dirtyWhite;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);

      h2,
      h4 {
        margin: 10px;
      }

      h4 {
        font-size: 16px;
        font-weight: normal;
      }
      @include correctDotColor;
    }
  }

  .ShrinkingHeaderWithContent {
    #content {
      border: none;
    }
  }

  .h2 {
    height: auto;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  .lower {
    margin-top: 30px;

    p,
    ul,
    table {
      margin-left: 60px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .questions {
    a {
      all: unset;
    }

    h4 {
      font-size: 16px;
      line-height: 24px;
      color: #000;
      margin: 0;
      font-weight: normal;
      cursor: pointer;
      clear: both;
      text-align: justify;
    }
  }

  .anchored {
    scroll-margin-top: 350px;

    .return-button {
      height: 40px;

      button {
        float: right;
        color: #272727;
        font-size: 14px;
        font-weight: 600;

        svg {
          background-color: rgba(0, 0, 0, 0.54);
          border-radius: 50%;
          color: #fff;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .FAQTemplate {
    margin-right: 0;
    .lower {
      .lower-inner {
        padding: 10px;
      }
    }
  }
}
