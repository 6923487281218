@import "style/variables.module";

.EAccountForm {
  margin: 30px;
  .form-wrapper {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    .to-right {
      float: right;
    }

    .buttons-container {
      display: flex;
      justify-self: center;
    }

    .MuiFormControlLabel-root {
      display: block;
      margin: 0;
    }

    .MuiFormLabel-root {
      color: rgba(0, 0, 0, 1);
    }

    * {
      max-width: 800px;
    }

    .more-margin {
      margin-left: 60px;
    }

    .multiple {
      margin: 10px 0;
    }

    .MuiFormHelperText-root {
      color: $error;
    }

    .send-button {
      width: 95px;
      display: flex;
      justify-self: flex-end;
      margin-right: 90px;
    }

    .MuiInputAdornment-root {
      svg {
        cursor: help;
      }
    }
  }

  .feedback {
    display: block;
    padding: 10px;
    margin: 0 50px 50px 50px;
    position: fixed;
    top: 50%;
    background: #fff;
    left: calc(50% - 100px);
    width: 200px;
    text-align: center;
    font-size: 19px;
    z-index: 1000;

    .positive {
      color: $success;
      border: 1px solid $success;
    }

    .negative {
      color: $error;
      border: 1px solid $error;
    }
  }
}

@media (max-width: 1200px) {
  .EAccountForm {
    .form-wrapper {
      .send-button {
        margin-right: 0;
      }
    }
  }
}
