@import "src/mixins.scss";
@import "style/variables.module";

.JobsTemplate {
  max-width: 960px;
  @include bannerWrapper;

  .jobs-content {
    padding: 48px 20px 30px 20px;

    h6 {
      margin: 0;
      padding: 0;
      font-size: 18px;
    }

    p {
      font-size: 16px;
      margin: 0;
      padding: 0;
      line-height: 24px;
    }

    h5 {
      width: fit-content;
      margin: 10px auto;
      padding: 3px 10px;
      font-size: 20px;
      color: #fff;
      background: #0097a7;
      border-radius: 5px;
    }
  }
  .banner {
    @include banner;
  }

  .CollapsableInfoCard {
    .content {
      box-sizing: border-box;
      box-shadow: 0 0 0 20px $primary;
      border-radius: 20px;
      margin: 10px 10px 50px 10px;
      padding: 10px 10px 50px 10px;

      div:first-child::after {
        content: "";
        width: 154px;
        height: 54px;
        position: absolute;
        right: 30px;
        background-image: url("../../../resources/dbrt-logo.png");
        background-color: #0097a7;
        background-repeat: no-repeat;
        background-position: bottom right;
        border-radius: 10px 0 0 0;
      }
    }

    h6 {
      color: $primary;
    }
  }
}

@media (max-width: 1024px) {
  .JobsTemplate {
    margin-right: 0;
  }
  .banner {
    display: none;
  }
}
