.HomeHeader {
  .header {
    z-index: 5;
  }
  .MuiTypography-root {
    white-space: nowrap;
  }

  & > .header {
    width: 100%;
  }
}
