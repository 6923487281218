.RadioCard {
  display: flex;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  max-width: 550px;
  padding: 15px 0 15px 0;
  cursor: pointer;
}

.RadioCard.checked {
  box-shadow: -3px 3px 4px 0px rgba(0, 0, 0, 0.16),
    0 0 0 1px rgba(0, 0, 0, 0.08) !important;
}
