.CollapsableInfoCard {
  margin-bottom: 15px;
  scroll-margin-top: 250px;

  h2 {
    font-size: 24px;
    text-align: left;
    margin: 0 0 0 10px;
    font-weight: 300;
  }

  .header {
    display: flex;
    align-items: center;
    min-height: 52px;
    margin-top: 15px;
    margin-bottom: 35px;

    .icon {
      min-width: 48px;
      width: 48px;
      height: 48px;
      background-repeat: no-repeat;
    }
  }

  .content {
    margin-top: 0;
    border: none;
    min-height: unset;
  }

  .pointer {
    cursor: pointer;
  }

  button {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .upper-border {
    border-top: 1px solid lightgray;
  }
}

@media (max-width: 1024px) {
  .CollapsableInfoCard {
    .header {
      h2 {
        font-size: 22px;
        font-weight: 400;
      }
    }
  }
}

@media (max-width: 480px) {
  .CollapsableInfoCard {
    .header {
      h2 {
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}
