@import "style/variables.module";

.ContactUsForm {
  .feedback {
    display: block;
    padding: 10px;
    margin: 0 50px 50px 50px;
    position: fixed;
    top: 50%;
    background: #fff;
    left: calc(50% - 100px);
    width: 200px;
    text-align: center;
    font-size: 19px;
    z-index: 1000;

    .positive {
      color: $success;
      border: 1px solid $success;
    }

    .negative {
      color: $error;
      border: 1px solid $error;
    }
  }

  .contact-form {
    background-color: $dirtyWhite;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    padding: 30px;

    h6 {
      font-size: 20px;
      margin: 15px 0 15px 0;
    }

    .form-wrapper > * {
      margin: 15px 0 15px 0;
    }

    .form-wrapper {
      margin-bottom: 30px;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      .address-and-popover {
        display: flex;
        align-items: center;

        .PopoverInfoIcon {
          padding-left: 5px;
        }
      }

      .category {
        margin-top: 5px;
      }

      .issue-details {
        .title {
          margin-top: 15px;
          margin-bottom: 15px;
        }
      }

      .attachment {
        .title {
          margin-top: 15px;
          margin-bottom: 15px;
        }

        .drop-zone {
          border: 1px dashed #9e9e9e;
          cursor: pointer;

          .inner {
            padding: 25px;
            display: inline-flex;

            .text {
              padding-left: 10px;
              padding-top: 3px;
            }
          }

          .upload-file {
            display: none;
          }
        }

        .attachment-uploaded {
          color: rgb(255, 255, 255);
          font-size: 13px;
          border: 1px dashed rgba(255, 255, 255, 0.25);
          background-color: rgb(117, 117, 117);
          margin-top: 15px;
          padding: 10px 15px;
        }
      }

      .files {
        margin: 10px 0;
        .file {
          margin: 5px 0;
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;

          svg {
            cursor: pointer;
            justify-self: end;
          }
        }
      }
    }

    .small-details {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
    }

    a {
      all: unset;
      color: $primary;
      cursor: pointer;
    }

    .dijnet-inline-logo {
      height: 19px;
      margin-bottom: -3px;
      cursor: pointer;
    }

    .second-part {
      h6 {
        text-align: center;
        margin: 30px 0 30px 0;
      }

      p {
        font-size: 18px;
      }

      .small-font {
        font-size: 15px;
      }
    }

    .send-me-detailed-info {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .small-font {
      margin-bottom: 20px;
    }
  }
  .consent-error {
    color: red;
    text-decoration: underline;
  }

  .errors {
    color: #e62511;
    border: 1px solid #e62511;
    display: block;
    position: static;
    width: auto;
    font-size: 16px;
    text-align: left;
    top: 50%;
    background: #fff;
    padding: 10px;
    margin: 0 25px 50px 25px;
    left: calc(50% - 100px);
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button:hover {
      background-color: $primary;
      color: #fff;
    }
  }
}
