@import "style/variables.module";

.ScrollToTop {
  .toTop {
    cursor: pointer;
    display: inline;
    text-decoration: none;
    position: fixed;
    bottom: 40px;
    right: 0;
    overflow: hidden;
    width: 70px;
    height: 70px;
    border: none;
    text-indent: -999px;

    .toTopHover {
      width: 70px;
      height: 70px;
      display: block;
      overflow: hidden;
      float: left;
      opacity: 0;
    }

    .toTopHover:hover {
      opacity: 1;
    }
  }

  .toTop:active,
  .toTop:focus {
    outline: none;
  }
}
