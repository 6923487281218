@import "style/variables.module";

.SearchResults {
  .search-results {
    max-width: 960px;

    .flex {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      margin: 5px 0 20px 0;
    }

    .results-wrapper {
      background-color: $dirtyWhite;
      padding: 20px 48px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);

      .content {
        max-height: 38px;
        overflow: hidden;
      }
    }
  }
}
