@import "style/variables.module";

.ElengedesPage {
  max-width: 960px;
  margin-top: 60px;
  margin-right: 350px;

  .content-inner {
    margin: 25px auto;
    max-width: 600px;

    .elengedes-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      * {
        margin: 5px 0;
        max-width: 600px;
      }

      button {
        margin: 0 0 0 auto;
      }

      button:hover {
        background-color: $primary;
        color: #fff;
      }

      .form-box {
        margin: 50px 0 25px 0;
        background-color: $dirtyWhite;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
          0 0 0 1px rgba(0, 0, 0, 0.08);
        padding: 30px 50px;
        width: 500px;
      }

      .stars {
        max-width: 550px;
      }
    }

    .elengedes-data {
      .address {
        text-align: center;
        margin: 30px 0;
      }

      .double {
        padding: 5px 60px;
      }

      .right {
        text-align: end;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      th,
      td {
        padding: 5px 40px;
      }

      .space {
        height: 10px;
      }

      .line {
        margin-top: 25px;
        height: 25px;
        border-top: 1px solid black;
      }
    }
  }
}

@media (max-width: 1500px) {
  .ElengedesPage {
    margin-right: 0;

    .content-inner {
      .elengedes-data {
        .double {
          padding: 5px 20px;
        }

        th,
        td {
          padding: 5px 10px;
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .ElengedesPage {
    .content-inner {
      .elengedes-form {
        .form-box {
          padding: 5px;
          width: 90%;
        }
      }
    }
  }
}
