@import "style/variables.module.scss";

.PictureTile {
  text-decoration: none;
  color: inherit;
  margin: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: none;
  width: 100%;
  height: 880px;

  .desktop {
    height: 100%;
    width: 100%;
    .top-part {
      position: relative;
      height: 400px;
      top: 0;
      background: linear-gradient(to bottom, #fff 55%, rgba(0, 0, 0, 0) 100%);
      z-index: 2;
    }

    .img {
      position: relative;
      margin-top: -300px;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
      height: 780px;
    }
  }

  h2 {
    width: auto;
    text-align: center;
    margin-top: 0;
    padding-top: 40px;
    font-size: 32px;
    color: #0097a7;
    font-weight: 300;
  }

  .mobile {
    display: none;
  }
}

@media (max-width: 1024px) {
  .PictureTile {
    height: 500px;

    .desktop {
      .top-part {
        height: 200px;
      }
      .img {
        height: 450px;
        margin-top: -150px;
      }
    }
  }
}

@media (max-width: 480px) {
  .PictureTile {
    height: 112px;
    margin: 0 0 10px 0;

    .desktop {
      background: rgba(255, 255, 255, 0.8);
      z-index: 1;

      .top-part {
        height: 112px;
        background: rgba(255, 255, 255, 0.8);
      }

      .img {
        height: 112px;
        margin-top: -112px;
      }

      h2 {
        font-size: 24px;
        padding-top: 14px;
        font-weight: 400;
      }
    }
  }
}
