@import "style/variables.module";

.PaymentPageTemplate {
  margin-top: 77px;

  .barion-warning {
    padding: 10px;
    background: #f44336;
    color: #fff !important;
    margin-bottom: 30px;
  }

  .CollapsePanel {
    margin-bottom: 60px;
  }

  button:hover {
    background-color: $primary;
    color: #fff;
  }

  h2 {
    width: 100%;
    height: auto;
    margin: 20px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    color: $primary;
  }

  .PaymentForm {
    .check-form {
      background-color: $dirtyWhite;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
      padding: 30px 50px;
      max-width: 400px;
    }

    .condition {
      margin-top: 15px;
      font-size: smaller;
      max-width: 500px;
    }

    .button-wrapper {
      max-width: 500px;
      display: flex;
      justify-content: flex-end;
    }

    // Ezek azert, hogy a number tipusu input mezobol
    // eltunjenek a fel-le nyilak a mezo vegen
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .error-message-container {
      margin: 20px 0;
      font-weight: 500;
    }
  }

  .payment-form {
    margin-top: 30px;
    background-color: $dirtyWhite;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    padding: 25px 25px 25px 25px;
    max-width: 400px;

    button {
      display: flex;
      margin: 0 0 0 auto;
      color: $primary !important;
    }
  }

  .payment-state {
    margin-top: 30px;
  }

  .barion {
    margin-top: 50px;
    img {
      max-width: 500px;
    }
  }

  .stars {
    max-width: 500px;
    margin-top: 50px;
    font-size: 80%;
  }

  .error-color {
    margin-top: 0;
  }
}
