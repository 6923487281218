@import "style/variables.module.scss";

.Actualities {
  background: $dirtyWhite;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;

  h2 {
    margin-top: 0;
  }

  button {
    width: 160px;
    font-size: 14px;
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  .header {
    font-size: 32px;
    font-weight: 500;
    color: $primary;
    width: 100%;
    text-align: center;
    padding: 16px 0 10px 0;
  }

  .content {
    width: 100%;
    height: 100%;
  }

  .button-wrapper {
    padding-bottom: 80px;
    padding-top: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 800px) {
  .Actualities {
    padding-left: 10px;
    padding-right: 10px;
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;
      justify-items: center;
    }
  }
}
