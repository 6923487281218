@import "style/variables.module.scss";
.ActualityCard.border-needed {
  border-bottom: solid 1px lightgray;
}

.ActualityCard {
  padding: 30px 0 30px 0;
  position: relative;
  z-index: 1;
  max-width: 640px;

  .main-pic {
    float: right;
  }

  h4 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 24px 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: $primary;
  }

  .text-wrapper {
    margin-bottom: 30px;
    display: table;

    img {
      width: 300px !important;
      margin-left: 10px;
    }

    @media (max-width: 1024px) {
      img {
        float: none !important;
        margin: auto auto 25px auto !important;
      }
    }

    .navigation {
      font-size: 18px;
      display: block;
      padding-top: 20px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .ActualityCard {
    margin: 18px 30px 18px 30px;
  }
}
