@import "style/variables.module";

.FoglalasTorlesTemplate {
  h1 {
    width: 100%;
    height: auto;
    margin: 20px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    color: $primary;
  }

  h4 {
    color: #333;
    font-size: 18px;
  }
}
