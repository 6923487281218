.PageLayout {
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 100vh;

  & > .content {
    flex: 1;
    display: flex;

    .content-row {
      flex: 1;
      flex-direction: column;
      display: flex;
      overflow: auto;

      .content-container {
        flex: 1;
        padding: 15px 20px 15px 20px;
      }
    }
  }
}

@media all and (max-width: 480px) {
  .PageLayout {
    & > .content {
      .content-row {
        .content-container {
          padding: 20px;
        }
      }
    }
  }
}
