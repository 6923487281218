@import "src/mixins.scss";
@import "style/variables.module";

.MultipleCollapsibleInfoPage {
  max-width: 960px;
  margin-right: 350px;
  @include correctDotColor;

  .multiple-collapse-info-page {
    padding: 48px 48px 35px 48px;
  }

  .MuiButton-root {
    color: $primary !important;
  }
}
@media (max-width: 1024px) {
  .MultipleCollapsibleInfoPage {
    margin-right: 0;

    .multiple-collapse-info-page {
      padding: 10px;
    }
  }
}

@media (max-width: 600px) {
  .MultipleCollapsibleInfoPage {
    .multiple-collapse-info-page {
      padding: 0;
    }
  }
}
