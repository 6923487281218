.SubpageHeader {
  .menu-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
  }

  & > .header {
    position: fixed;
    width: 100%;
    z-index: 3;
  }
}

@media (max-width: 480px) {
  .SubpageHeader {
    & > .header {
      position: relative;
      width: 100%;
      z-index: 3;
    }
  }
  .header-placeholder {
    display: none;
  }
}
