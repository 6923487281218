.BlueBanner {
  position: fixed;
  top: 125px;
  right: 25px;
  width: 300px;
  height: 600px;
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;

  @media (min-width: 1640px) {
    left: 1315px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
