@import "src/mixins.scss";
@import "style/variables.module";

.EAccountEmailTemplate {
  max-width: 960px;
  margin-right: 350px;

  .content {
    padding: 50px !important;

    @include correctDotColor;

    .faq-item {
      padding: 30px 0;
      border-top: 1px solid $lightGrey;

      h3 {
        font-size: 16px;
        color: #272727;
        margin-bottom: 15px;
      }

      p {
        color: #272727;
        font-size: 16px;
        line-height: 24px;
      }

      button {
        font-size: 0.8em;
        font-weight: bold;
        margin-top: 20px;
        padding: 0;
        justify-content: flex-start !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .EAccountEmailTemplate {
    margin-right: 0;
    .content {
      padding: 10px !important;
      h2 {
        height: 60px;
        margin-top: 45px;
        font-size: 22px;
      }
    }
  }
}
