.SubpageSelector {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1025px) {
  .SubpageSelector {
    flex-direction: column;
    gap: 20px;
  }
}
