@import "style/variables.module";

.PageFooter {
  a {
    text-decoration: none;
    color: $white;
    margin-left: 10px;
  }

  p {
    font-size: 12px;
  }
}
