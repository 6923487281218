@import "style/variables.module";

.AppointmentListItem {
  display: grid;
  grid-template-columns: 1fr 6fr 6fr 2fr;
  padding: 15px 0 15px 0;
  box-shadow: 0 4px 4px 0 rgba(179, 179, 179, 16%),
    0 0 0 1px rgba(179, 179, 179, 8%);
  background-color: $dirtyWhite;

  .MuiRadio-root {
    height: 42px;
    width: 42px;
    align-self: center;
    justify-self: center;
  }

  .basic-info {
    padding: 0 30px 0 0;
    display: flex;
    border-right: 1px solid grey;

    .first-row {
      min-width: 320px;
    }

    .icon {
      padding-right: 20px;
    }

    .address {
      font-size: 16px;
      padding-top: 5px;
      font-weight: 600;
    }

    .address-details {
      margin-top: 10px;
    }

    .available-services {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }

  .times {
    padding: 0 0 0 60px;
    .opening-hours-title {
      font-size: 16px;
      font-weight: 600;
      padding-top: 5px;
      margin-bottom: 15px;
    }

    .opening-hours {
      .day {
        display: flex;
        justify-content: space-between;
        min-height: 35px;

        div {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
  .appointment {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 160px;

    button {
      max-width: 160px;
    }
  }
}

.AppointmentListItem.dark {
  background-color: #fafafa;
}

.AppointmentListItem.radio {
  cursor: pointer;
}

.AppointmentListItem.checked {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 16%), 0 0 0 1px rgba(0, 0, 0, 8%);
}

@media (max-width: 900px) {
  .AppointmentListItem {
    display: grid;
    grid-template-columns: 1fr 8fr;
    grid-template-areas:
      "a b d"
      "a c d";

    .basic-info {
      border-right: none;
      border-bottom: 1px solid grey;
      grid-area: b;
      margin-right: 50px;
    }
    .times {
      grid-area: c;
      margin-right: 50px;
    }
    .appointment {
      grid-area: d;
    }
    .MuiRadio-root {
      grid-area: a;
    }
  }
}

@media (max-width: 900px) {
  .AppointmentListItem {
    .basic-info {
      padding: 0;
    }
    .times {
      padding: 0;
    }
  }
}

@media (max-width: 600px) {
  .AppointmentListItem {
    .basic-info {
      .first-row {
        min-width: unset;
      }
    }
  }
}
