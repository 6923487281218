.BlogEntryPage {
  .main-pic {
    width: 100%;
    max-width: 300px;
    float: right;

    img {
      width: 100%;
    }
  }
}