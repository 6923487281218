.Menu {
  height: 100%;
  z-index: 10;

  .menu-container {
    position: fixed;
    transition: all 200ms linear;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #ffffff;
    z-index: 1;

    input::placeholder {
      color: #000;
      font-weight: 500;
    }

    span {
      font-size: 14px;
      font-weight: 500;
    }

    .MuiMenuItem-root {
      height: 42px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .MuiList-root {
      margin: 0;
      padding: 0;
    }

    .MuiDivider-root {
      margin: 0 15px 0 15px;
    }
  }

  .menu-placeholder {
    transition: all 200ms linear;
  }
}
