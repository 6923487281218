@import "style/variables.module.scss";

@mixin colors {
  //purple
  .gradient-tile-wrapper.purple {
    border: none;
  }
  .gradient-tile-wrapper.purple:hover {
    border: 5px solid $primary-purple;
  }
  .gradient-tile-inner.purple {
    background: rgba(168, 33, 107, 0.9);
  }
  //orange
  .gradient-tile-wrapper.orange {
    border: none;
  }
  .gradient-tile-wrapper.orange:hover {
    border: 5px solid $primary-orange;
  }
  .gradient-tile-inner.orange {
    background: rgba(255, 160, 0, 0.9);
  }
  //green
  .gradient-tile-wrapper.green {
    border: none;
  }
  .gradient-tile-wrapper.green:hover {
    border: 5px solid $primary-green;
  }
  .gradient-tile-inner.green {
    background: rgba(124, 179, 66, 0.9);
  }
}

.GradientTile {
  text-decoration: none;
  color: inherit;
  width: 100%;
  background-size: cover;
  background-position: center;

  .gradient-tile-wrapper {
    border: 5px solid rgba(255, 255, 255, 1);
    transition: 200ms;
  }

  .gradient-tile {
    height: 400px;
    transition: 300ms;

    .gradient-tile-inner {
      width: 100%;
      height: 100%;

      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);

      opacity: 1;

      display: grid;
      grid-template-rows: 1fr 1fr;
      align-items: center;
    }
  }

  h2 {
    text-align: center;
    color: $white;
    margin: 50px 0 0 0;
    font-size: 40px;
    font-weight: 400;
    align-self: start;
  }

  .mobile {
    display: none;
  }

  // purple
  .gradient-tile-wrapper.purple {
    border: 5px solid rgba(255, 255, 255, 1);
  }
  .gradient-tile-wrapper.purple:hover {
    border: 5px solid $primary-purple;
  }
  .gradient-tile-inner.purple {
    background: linear-gradient(rgb(168, 33, 107), rgba(0, 0, 0, 0) 38%);
  }

  //orange
  .gradient-tile-wrapper.orange {
    border: 5px solid rgba(255, 255, 255, 1);
  }
  .gradient-tile-wrapper.orange:hover {
    border: 5px solid $primary-orange;
  }
  .gradient-tile-inner.orange {
    background: linear-gradient(rgb(255, 160, 0), rgba(0, 0, 0, 0) 38%);
  }

  //green
  .gradient-tile-wrapper.green {
    border: 5px solid rgba(255, 255, 255, 1);
  }
  .gradient-tile-wrapper.green:hover {
    border: 5px solid $primary-green;
  }
  .gradient-tile-inner.green {
    background: linear-gradient(rgb(124, 179, 66), rgba(0, 0, 0, 0) 38%);
  }
}

@media only screen and (max-width: 1025px) and (min-width: 480px) {
  .GradientTile {
    width: 100%;
    height: 230px;

    .desktop {
      display: none;
    }
    .mobile {
      display: block;

      .gradient-tile {
        height: 230px;
        width: 100%;

        .gradient-tile-inner {
          width: 100%;
        }
      }

      .next-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .next-button {
        height: 36px;
        line-height: 36px;
        width: 160px;
        display: flex;
        justify-content: center;
        background-color: $white;
      }
    }

    @include colors;
  }
}

@media all and (max-width: 480px) {
  .GradientTile {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;

      .gradient-tile {
        max-height: 140px;
        width: 100%;

        .gradient-tile-inner {
          width: 100%;

          h2 {
            font-size: 24px;
            margin-top: 20px;
          }
        }
      }

      .next-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .next-button {
        height: 36px;
        font-size: 14px;
        font-weight: 700;
        line-height: 36px;
        text-transform: uppercase;
        width: 160px;
        display: flex;
        justify-content: center;
        background-color: $white;
      }
    }

    @include colors;
  }
}
