.ServiceCharges {
  max-width: 960px;
  margin-top: 60px;
  margin-right: 350px;

  background: #f6f6f6;

  .content {
    padding: 48px 48px 30px 48px;

    .dbrt_tablazat {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      text-transform: uppercase;
      background: #f4f4f4;
      overflow-x: auto;
    }
    .dbrt_tablazat h6 {
      width: 100%;
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
      text-transform: none;
      background: #fff;
    }

    .tablazat_fejlec {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      p {
        width: 25%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;

        &:nth-of-type(1) {
          min-width: 360px;
          width: 50%;
          background: #0097a8;
        }

        &:nth-of-type(2) {
          min-width: 180px;
          background: #008290;
        }

        &:nth-of-type(3) {
          min-width: 180px;
          background: #026e7b;
        }
      }
    }

    .tabla_olvasztva {
      width: 100%;
      display: flex;
      justify-content: flex-start;

      &:nth-of-type(odd) .tabla_nagy_kocka {
        background: #e1e1e1;
      }
    }

    .tabla_nagy_kocka {
      min-width: 180px;
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .tablazat_sorok {
      width: 75%;
    }

    .tablazat_sor {
      min-width: calc(3 * 180px);
      width: 100%;
      display: flex;
      justify-content: flex-start;

      .tablazat_cella {
        min-width: 180px;
        width: calc(100% / 3);
        min-height: 60px;
        box-sizing: border-box;
        padding: 5px 0;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        border-left: 1px solid #ccc;
      }

      &:nth-of-type(odd) {
        background: #e1e1e1;
      }

      .tablazat_cella p {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;

        &:nth-of-type(2) {
          width: 100%;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .egyszeru_tablazat_sor {
      min-width: calc(4 * 180px);
      width: 100%;
      display: flex;
      justify-content: flex-start;

      &:nth-of-type(odd) {
        background: #e1e1e1;
      }
    }

    .egyszeru_tablazat_cella {
      min-width: 180px;
      width: 25%;
      box-sizing: border-box;
      padding: 10px 13px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;
      border-left: 1px solid #ccc;

      &:first-of-type {
        border: none;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;

        &:nth-of-type(2) {
          width: 100%;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    h2 {
      margin: 0 0 0 20px;
      font-size: 24px;
      font-weight: 300;
    }
  }

  h4 {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 18px;
  }

  h6 {
    width: 100%;
    padding: 20px 0;
    font-size: 20px;
    text-align: center;
    text-transform: none;
    background: #fff;
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .ServiceCharges {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .ServiceCharges {
    margin-top: 0;

    .ShrinkingHeaderWithContent {
      margin-top: 0;
    }
  }
}
