@import "style/variables.module";
@import "src/mixins.scss";

.BefizetesModjaiTemplate {
  max-width: 960px;
  margin-right: 350px;

  .content-wrapper {
    padding: 30px 48px 35px 48px;

    h2 {
      margin: 0 0 0 20px;
      font-size: 24px;
      font-weight: 300;
      padding: 0;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        padding-left: 15px;
        position: relative;
        list-style: none;
        font-size: 16px;
        line-height: 24px;
      }

      li::before {
        content: "•";
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    .header {
      display: flex;
      align-items: center;
      margin-top: 30px;
      margin-bottom: 15px;

      .icon {
        margin-right: 15px;
      }
    }

    .lower-border {
      border-bottom: 1px solid $lightGrey;
      padding-bottom: 30px;
      * {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  @include correctDotColor;
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .BefizetesModjaiTemplate {
    .content-wrapper {
      h2 {
        font-size: 28px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .BefizetesModjaiTemplate {
    margin-right: 0;
    .content-wrapper {
      padding: 10px;
      h2 {
        height: 60px;
        margin-top: 45px;
        font-size: 22px;
      }
    }
  }
}
