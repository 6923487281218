.SearchBox {
  .MuiFocused {
    color: white !important;
  }

  .MuiInputLabel-root {
    color: white !important;
  }

  .MuiInput-root {
    color: white !important;
    padding-right: 0 !important;
  }

  .MuiInput-root:before {
    border-bottom-color: white !important;
  }

  .MuiInput-root:hover:before {
    border-bottom-color: white !important;
  }

  .MuiInputAdornment-root {
    color: white !important;
  }

  input::placeholder {
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
  }
}

.SearchBox.menu {
  .MuiInputAdornment-root {
    color: rgba(0, 0, 0, 0.54) !important;
  }
}
