@import "style/variables.module";

.ShrinkingHeader {
  max-width: 960px;
  position: fixed;
  top: 117px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  z-index: 2;

  width: 100%;
  height: 224px;
  display: flex;
  justify-content: flex-end;
  transition: 300ms;

  h2 {
    position: absolute;
    top: 50%;
    left: 48px;
    transform: translateY(-50%);
    font-size: 40px !important;
    font-weight: 500 !important;
    transition: 300ms;
    z-index: 3;
  }

  .header-image {
    width: 100%;
    max-width: 649px;
    position: relative;
    z-index: 1;
    background-size: cover;

    .go-back {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 17px;
      right: 24px;
      z-index: 3;
      cursor: pointer;
    }

    .go-back::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }
  }

  .header-image::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }

  .MuiSvgIcon-root {
    color: $white;
  }
}

.ShrinkingHeader.purple {
  background: $primary-purple;
  .header-image::after {
    background: linear-gradient(
      to right,
      $primary-purple,
      rgba(255, 160, 0, 0)
    );
  }
}

.ShrinkingHeader.orange {
  background: $primary-orange;
  .header-image::after {
    background: linear-gradient(
      to right,
      $primary-orange,
      rgba(255, 160, 0, 0)
    );
  }
}

.ShrinkingHeader.green {
  background: $primary-green;
  .header-image::after {
    background: linear-gradient(to right, $primary-green, rgba(255, 160, 0, 0));
  }
}

.ShrinkingHeader.blue {
  background: $primary;
  .header-image::after {
    background: linear-gradient(to right, $primary, rgba(255, 160, 0, 0));
  }
}

.ShrinkingHeader.shrunk {
  height: 112px;
  top: 117px;

  h2 {
    font-size: 28px !important;
    top: 43%;
  }
}

@media (max-width: 800px) {
  .ShrinkingHeader {
    h2 {
      font-size: 28px;
    }
  }
}

@media (max-width: 600px) {
  .ShrinkingHeader {
    position: relative;
    display: flex;
    justify-content: flex-start;
    background: transparent !important;
    box-shadow: unset;
    height: auto;
    margin: 20px 0;
    top: 0;

    h2 {
      padding-left: 16px;
      position: static;
      transform: none;
      font-size: 32px !important;
    }

    .header-image {
      display: none;
    }
  }

  .ShrinkingHeader.shrunk {
    height: auto;
    top: 0;

    h2 {
      padding-left: 16px;
      position: static;
      transform: none;
      font-size: 32px !important;
    }
  }
}
