.Career {
  display: flex;


}

@media only screen and (max-width: 900px) {
  .Career {
    flex-wrap: wrap;
  }
  }