$primary: #0097a7;
$primary-inverse: #ffffff;
$secondary: #8ac640;
$error: #d32f2f;
$warning: #ed6c02;
$info: #0288d1;
$success: #4caf50;

$white: #fff;
$dirtyWhite: #fafafa;
$lightBlue: #0097a726;
$lightGrey: #cdcdcd;

$primary-purple: #a8216b;
$primary-orange: #ffa000;
$primary-green: #7cb342;

:export {
  primary: $primary;
  primaryInverse: $primary-inverse;
  secondary: $secondary;
  error: $error;
  warning: $warning;
  info: $info;
  success: $success;
  white: $white;
  dirtyWhite: $dirtyWhite;
  primaryPurple: $primary-purple;
  primaryOrange: $primary-orange;
  primaryGreen: $primary-green;
  lightBlue: $lightBlue;
  lightGrey: $lightGrey;
}
