@import "variables.module";

.primary-background-color {
  background-color: $primary;
}
.secondary-background-color {
  background-color: $secondary;
}
.error-background-color {
  background-color: $error;
}
.warning-background-color {
  background-color: $warning;
}
.info-background-color {
  background-color: $warning;
}
.success-background-color {
  background-color: $success;
}

.primary-color {
  color: $primary;
}

.primary-inverse-color {
  color: $primary-inverse;
}

.secondary-color {
  color: $secondary;
}
.error-color {
  color: $error;
}
.error-color {
  color: $error;
}
.info-color {
  color: $info;
}
.success-color {
  color: $success;
}
