@import "style/variables.module";
@import "src/mixins.scss";

.Ugyfelfogadas {
  max-width: 960px;
  background: #fafafa;

  .title-text {
    padding: 16px 48px 36px 48px;
    p,
    div {
      color: #272727;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .title-text:after {
    content: "";
    height: 1px;
    display: block;
    background: rgba(0, 0, 0, 0.12);
  }

  .card-wrapper {
    display: grid;
    grid-template-columns: 1fr 9fr 3fr;
    margin-left: 30px;
    margin-right: 30px;

    button {
      width: 160px;
      height: 36px;
      text-align: center;
      display: table-cell;
      vertical-align: middle;
    }

    img {
      margin-top: 10px;
      justify-self: center;
    }

    a {
      max-height: 50px;
      align-self: center;
      justify-self: center;
      text-decoration: none;
    }

    .iroda_basic {
      width: 100%;
      min-width: 320px;
      padding: 0;

      h3 {
        width: auto;
        margin: 0;
        position: relative;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: #272727;
      }

      h5 {
        font-size: 16px;
        color: #333;
      }

      p {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .iroda_nyitva {
      width: 100%;

      h6 {
        margin: 0 16px 26px 0;

        font-size: 16px;
        font-weight: 600;
        color: #000;
      }

      p {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .even {
    background-color: #f5f5f5;
  }

  .odd {
    background-color: $dirtyWhite;
  }

  .card {
    display: flex;
  }

  .iroda_nyitva {
    padding: 10px 60px 10px 60px;
  }

  .iroda_basic {
    width: 280px;
    padding: 0 60px 0 0;
    margin: 10px 0 10px 0;
    border-right: 1px solid $lightGrey;
  }
}

@media (max-width: 1024px) {
  .Ugyfelfogadas {
    margin-right: 0;
    #content {
      padding: 48px 10px 30px 10px !important;
      .cards {
        .title-text {
          padding: 10px;
        }
        .card-wrapper {
          display: flex;
          flex-direction: column;
          margin: 0 0 60px 0;

          img {
            display: none;
          }

          .card {
            flex-direction: column;

            .iroda_basic {
              border: none;
              margin: 0;
              padding: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              //border-bottom: 1px solid $lightGrey;
            }

            .iroda_basic::after {
              content: "";
              width: 80%;
              height: 1px;
              background-color: $lightGrey;
            }

            .iroda_nyitva {
              margin: 0;
              padding: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              h6 {
                margin: 0;
                padding-top: 20px;
              }

              p {
                width: 60%;
              }
            }
          }
        }
      }
    }
  }
  .banner {
    display: none;
  }
}

@media (max-width: 480px) {
  .Ugyfelfogadas {
    #content {
      .cards {
        .card-wrapper {
          .card {
            .iroda_basic {
              min-width: unset;
            }
          }
        }
      }
    }
  }
}
