@import "style/variables.module";

.CMSPage {
  .undefined-variant {
    .content-container {
      .primary-background-color {
        background-color: $primary;
      }
    }

    .menu-container .MuiSvgIcon-root {
      color: $primary;
    }
  }
  .purple-variant {
    .content-container {
      .primary-background-color {
        background-color: $primary-purple;
      }
    }

    .menu-container .MuiSvgIcon-root {
      color: $primary-purple;
    }
  }

  .orange-variant {
    .content-container {
      .primary-background-color {
        background-color: $primary-orange;
      }
    }

    .menu-container .MuiSvgIcon-root {
      color: $primary-orange;
    }
  }

  .green-variant {
    .content-container {
      .primary-background-color {
        background-color: $primary-green;
      }
    }

    .menu-container .MuiSvgIcon-root {
      color: $primary-green;
    }
  }
}
