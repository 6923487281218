@import "style/variables.module";
@import "mixins.scss";

.MeterReadingDeclaration {
  margin: 30px 0 0 30px;

  .CollapsePanel {
    margin-bottom: 20px;

    .collapse-header {
      h3 {
        font-size: 18px;
      }
    }

    .collapse-content {
      color: #272727;
    }
  }

  .sub-title {
    width: 100%;
    height: auto;
    margin: 77px 0 20px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    color: $primary;
  }

  @include correctDotColor;

  th,
  td {
    padding: 5px 10px 5px 10px;
  }

  .MeterReadingForm {
    margin-top: 25px;

    .button-wrapper {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
    }

    button {
      color: $primary;
    }

    button:hover {
      background-color: $primary;
      color: #fff;
    }

    .reading-wrapper {
      max-width: 500px;
      .reading {
        background-color: $dirtyWhite;
        padding: 30px 30px 30px 30px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
          0 0 0 1px rgba(0, 0, 0, 0.08);

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        input[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }

    .give-readings {
      padding: 30px 0 30px 0;
      color: #4caf50;
      font-size: 16px;
    }

    .no-value-error {
      color: #f44336;
      font-size: 16px;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
    .give-reading-values-wrapper {
      max-width: 500px;

      .meter-reading {
        .wrapper {
          padding: 45px;
          border: none;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16),
            0 0 0 1px rgba(0, 0, 0, 0.08);
          background: #fafafa;
          margin-top: 30px;
          clear: both;
          display: block;
          color: #838383;
          font-size: 14px;
          max-width: 500px;
        }

        .done {
          color: #4caf50;
          padding: 15px 0 15px 0;
          display: block;
          font-size: 16px;
        }

        .error {
          color: #f44336;
          padding: 15px 0 15px 0;
          display: block;
          font-size: 16px;
        }
      }
    }
  }

  .infos-errors {
    max-width: 500px;
    .errors {
      padding-top: 25px;

      .error {
        color: #f44336;
      }
    }
    .small-font {
      font-size: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .MeterReadingDeclaration {
    margin: 0;
    max-width: none;
    #content {
      padding: 48px 10px 30px 10px !important;
    }
  }
}
