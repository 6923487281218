@import "../node_modules/@fontsource/roboto/300.css";
@import "../node_modules/@fontsource/roboto/400.css";
@import "../node_modules/@fontsource/roboto/500.css";
@import "../node_modules/@fontsource/roboto/700.css";

@import "style/default-classes.scss";

body {
  margin: 0;
  font-family: Roboto, serif;
  scroll-behavior: smooth;

  .MuiTooltip-popper {
    * {
      font-size: 12px;
      padding: 10px;
      color: #333;
      background: #fffcd9;
    }
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
  }

  button {
    border-radius: 0 !important;
  }

  a {
    text-decoration: none;
    color: #0097a7;
  }

  h1 {
    font-size: 24px;
  }

  h3 {
    margin: 0;
    padding: 0;
    line-height: 24px;
    font-size: 20px;
  }

  h5 {
    color: #333;
    font-size: 16px;
    margin: 0;
    padding: 0;
    line-height: 24px;
  }

  pre {
    margin: 0;
    padding: 0;
  }

  .CookieConsent {
    div {
      margin: 0 !important;
      font-size: 14px !important;
      font-weight: 300 !important;
    }
    button {
      margin: 0 !important;
    }
  }

  #content {
    figure {
      img {
        max-width: 100%;
      }
    }

    .image {
      display: flex;
      justify-content: center;
    }

    .image-style-side {
      float: right;
    }

    .image.image_resized {
      margin: 0 auto;
    }
  }
}
