@import "style/variables.module";

.BigNavigationBox {
  position: relative;
  text-decoration: none;
  color: inherit;
  min-height: 220px;
  max-height: 400px;

  button {
    min-width: 160px;
    color: #ffffff;
  }

  .bg {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    top: 0;
    z-index: -1;
  }

  .big-navigation-box {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);

    h2 {
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      margin: 40px 0 0 0;
    }

    .icon-container {
      border-radius: 50%;
      width: 160px;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 100px;
        height: 100px;
      }

      & > * {
        color: $white;
      }
    }

    .top-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mobile {
    display: none;

    button {
      min-width: unset;
      border-radius: 50% !important;
    }
  }

  .desktop {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .BigNavigationBox {
    .big-navigation-box {
      max-height: 240px;

      .top-container {
        width: 48px;
        height: 48px;
        padding: 5px;
        * {
          background-size: cover;
        }
      }

      h2 {
        margin: 0;
        font-size: 20px;
      }
    }

    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
    .top-container {
      svg {
        width: 48px;
        height: 48px;
      }
    }
    .icon-container {
      width: 48px;
      height: 48px;
    }
  }
}
