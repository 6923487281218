@import "style/variables.module";

.LetterPicker {
  .letters-wrapper {
    text-align: center;

    .btn {
      border: 1px solid $primary;
      color: $primary;
      padding: 5px;
      cursor: pointer;
    }

    .btn:hover,
    .btn.selected {
      border: 1px solid $primary;
      color: $white;
      background-color: $primary;
    }

    .disabled {
      border: 1px solid $lightGrey;
      color: $lightGrey;
      padding: 5px;
    }

    span {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}
