@import "style/variables.module";

.Breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 53px;
  background-color: $white;
  color: $primary;

  .element-wrapper {
    display: flex;
  }

  .element {
    margin-right: 5px;
  }

  a,
  div {
    text-decoration: none;
    color: $primary;
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 24px;
    white-space: nowrap;
  }
}

@media (min-width: 600px) {
  .Breadcrumb {
    position: fixed;
    top: 64px;
    width: 100%;
    z-index: 1;

    .element-wrapper {
      .element {
        * {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .Breadcrumb {
    //flex-direction: column;
    //align-items: baseline;
  }
}
