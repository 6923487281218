@import "src/mixins.scss";
@import "style/variables.module";

.HivjonTemplate {
  max-width: 960px;
  margin-top: 60px;
  margin-right: 350px;

  #content {
    padding: 48px 48px 30px 48px;
  }

  h3 {
    color: #272727;
    font-size: 20px;
  }

  .content-0 {
    margin-top: 30px;
  }

  h5 {
    color: #333;
    font-size: 16px;
  }

  .blog {
    h2 {
      margin: 0;
      font-size: 24px;
      text-align: left;
      font-weight: bold;
    }
  }

  h4 {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 18px;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      padding-left: 15px;
      position: relative;
      list-style: none;
      line-height: 24px;
    }

    li::before {
      content: "•";
      position: absolute;
      left: 0;
      top: 0;
      color: $primary;
    }
  }

  div:not([class]) {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
  }
  @include correctDotColor;
}

@media (max-width: 1024px) {
  .HivjonTemplate {
    margin-right: 0;
    max-width: unset;
  }
}

@media (max-width: 600px) {
  .HivjonTemplate {
    margin-top: 0;

    .ShrinkingHeaderWithContent {
      margin-top: 0;
    }
  }
}
