.CareerBanner {
  position: fixed;
  top: 125px;
  right: 25px;
  width: 300px;
  z-index: 100;

  padding: 10px;
  box-sizing: border-box;
  height: 600px;

  h3 {
    font-size: 20px;
    margin: 20px 0;
    color: #fff;
    text-align: center;
  }

  h4 {
    font-size: 16px;
    margin: 10px 0;
    color: #fff;
    line-height: 19px;
  }

  img {
    position: absolute;
    bottom: 0;
    left: calc(50% - 50px);
  }

  @media (min-width: 1640px) {
    left: 1315px;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
