.ActualitiesPage {
  padding: 0 48px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  background: #fafafa;
  max-width: 1280px;

  h1 {
    width: 100%;
    height: auto;
    margin: -20px 0 20px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    color: #0097A7;
    padding-top: 40px;
  }

  .pagination-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 25px;
  }

  .ActualityCard {
    margin: 0;
    max-width: inherit;
  }
}
