.FogalomtarTemplate {
  max-width: 960px;
  margin-right: 350px;

  .content-wrapper {
    padding: 30px 48px 35px 48px;

    h3 {
      font-size: 16px;
      margin: 0;
      padding: 0;
      line-height: 24px;
    }

    .content {
      margin-top: 0;
      border: none;
      min-height: unset;
    }
  }

  .LetterPicker {
    margin-top: 20px;
  }
}

@media (max-width: 1024px) {
  .FogalomtarTemplate {
    margin-right: 0;
    .content-wrapper {
      padding: 10px;
    }
  }
}
