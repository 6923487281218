.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 56px;
  padding: 0 20px 0 20px;

  a {
    display: inline;
    margin: 0;
  }

  .left-content {
    padding: 0;
    display: flex;
    align-items: center;
  }

  .right-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
  }
}

@media (max-width: 1000px) and (min-width: 480px) {
  .Footer {
    padding: 20px;

    .right-inner {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}

@media (max-width: 480px) {
  .Footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 20px;

    .right-content {
      flex: 1;
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      padding-bottom: 20px;
      width: 100%;
      justify-content: normal;
    }

    .left-content {
      padding-top: 20px;
    }

    .right-inner {
      display: grid !important;

      a {
        padding: 10px 0;
        font-size: 13px;
      }
    }
  }
}
