@import "style/variables.module";

.Header {
  height: 64px;
  display: flex;
  padding: 0 20px 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  .left-content {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .middle-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 30px;
      font-weight: 400;
      height: 37px;
    }
    h4,
    h5,
    h6 {
      color: $white;
    }
  }

  .right-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
