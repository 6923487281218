.ArticleTile {
  //height: auto;
  text-decoration: none;
  color: inherit;
  border: 2px solid;
  padding: 25px;
  box-sizing: border-box;
  //height: 305px;

  .icon-text {
    font-size: 32px;
  }

  .article-tile {
    text-decoration: none;
    color: inherit;

    .article-tile-container {
      text-align: center;
      margin-top: 10px;

      .icon {
        margin-bottom: 17px;
        min-height: 80px;
      }
    }

    .main-text {
      margin-top: 15px;
      font-size: 20px;
    }

    .more {
      margin-top: 40px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
