.TestPage {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;

  .ShrinkingHeaderWithContent {
    width: 60%;
  }
}
