.BottomBoxes {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  .ArticleTile {
    margin-bottom: 5px;
  }
}

@media (max-width: 900px) {
  .BottomBoxes {
    display: flex;
    flex-direction: column;
  }
}
