@import "src/mixins.scss";
.DownloadableForms {
  max-width: 960px;
  margin-right: 350px;

  .content {
    padding: 20px 48px 30px 48px;

    a {
      font-size: 20px;
      text-decoration: none;
    }
  }

  h2 {
    margin: 10px 0 10px -5px;
    height: auto;
    font-size: 24px;
    font-weight: 300;
  }

  h4 {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 18px;
  }
}

@media (max-width: 1024px) {
  .DownloadableForms {
    margin-right: 0;
  }
}
