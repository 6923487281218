@mixin banner() {
  position: fixed;
  top: 125px;
  right: 25px;
  width: 300px;
  height: 600px;

  @media (min-width: 1640px) {
    left: 1315px;
  }
}

@mixin correctDotColor() {
  .green {
    ul {
      li::before,
      li::marker {
        color: $primary-green;
      }
    }
  }
  .orange {
    ul {
      li::before,
      li::marker {
        color: $primary-orange;
      }
    }
  }
  .purple {
    ul {
      li::before,
      li::marker {
        color: $primary-purple;
      }
    }
  }
  .blue {
    ul {
      li::before,
      li::marker {
        color: $primary;
      }
    }
  }
}

@mixin bannerWrapper() {
  position: relative;
  margin-right: 340px;
}
