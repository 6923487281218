@import "style/variables.module";

.TileGrid {
  .title {
    width: 100%;
    height: auto;
    margin: 0 0 20px 0;
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    color: $primary;
  }

  .three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .default {
    display: flex;
    flex-basis: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;

    .BigNavigationBox {
      min-width: 500px;
      margin: 15px;
    }
  }

  .one-three {
    .first-row {
      display: grid;
    }

    .second-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 10px;
      margin-top: 20px;
    }
  }

  .three-two {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    // ez egy nagyon csúnya megoldás, de fogalmam sincs hogy miért
    // jelentek meg rosszul az ikonok ebben a 2 esetben, minden más
    // esetben jól működött. css teljesen ugyanaz volt
    .BigNavigationBox:nth-child(4) {
      .icon-container {
        div {
          opacity: 0.5;
        }
      }
    }
    .BigNavigationBox:nth-child(5) {
      .icon-container {
        div {
          opacity: 0.5;
        }
      }
    }
  }

  .three-three {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin: 0;
  }
}

@media (min-width: 480px) {
  .TileGrid {
    .three-two {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "first first second second third third"
        "fourth fourth fourth fifth fifth fifth";
      grid-gap: 10px;

      .BigNavigationBox:nth-child(1) {
        grid-area: first;
      }

      .BigNavigationBox:nth-child(2) {
        grid-area: second;
      }

      .BigNavigationBox:nth-child(3) {
        grid-area: third;
      }

      .BigNavigationBox:nth-child(4) {
        grid-area: fourth;
      }

      .BigNavigationBox:nth-child(5) {
        grid-area: fifth;
      }
    }

    .three-three {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: 1fr;
      grid-gap: 10px;
      margin: 0;
    }
  }
}

@media (max-width: 480px) {
  .TileGrid {
    .one-three {
      .second-row {
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        margin: 0;
      }
    }

    .one-three {
      .first-row {
        margin: 0 0 10px 0;
      }
    }

    .three-three,
    .default {
      display: flex;
      flex-direction: column;

      .BigNavigationBox {
        min-width: unset;
        min-height: 80px;
        height: 80px;
        .big-navigation-box {
          display: flex;
          .mobile {
            .top-container {
              margin-left: 17px;
            }
          }

          h2 {
            flex: 5;
            text-align: initial;
            margin-left: 10px;
            font-size: 16px;
          }

          .button-container {
            flex: 1;

            .MuiButton-root {
              float: right;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .one-three {
      .second-row {
        .BigNavigationBox {
          min-height: 80px;
          height: 80px;

          .big-navigation-box {
            display: flex;

            h2 {
              flex: 5;
              text-align: initial;
              margin-left: 10px;
            }

            .button-container {
              flex: 1;

              .MuiButton-root {
                float: right;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
