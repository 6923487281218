@import "style/variables.module";
@import "src/mixins.scss";

.OnlineBooking {
  max-width: 1152px;

  h4 {
    color: #333;
    font-size: 18px;
    margin: 0;
  }

  .title {
    font-size: 40px;
    font-weight: 500;
    line-height: 44px;
    margin: 80px 0 20px 0;
  }

  .OnlineBookingPageOne {
    .cards {
      display: flex;
      flex-direction: column;

      .MuiFormGroup-root {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }

    .RadioCard {
      margin: 15px;
    }

    h4 {
      margin: 0;
      font-size: 18px;
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;

    button {
      font-weight: 600;
      font-size: 14px;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    button:hover {
      background-color: $primary;
      color: #fff;
    }
  }

  .OnlineBookingPageTwo {
    .AppointmentListItem {
      margin: 30px;
    }
  }

  .OnlineBookingPageThree {
    margin-left: 30px;
    display: inline-grid;
    min-width: 550px;

    fieldset {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .date-picker {
      display: inline-block;
      background-color: $dirtyWhite;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
      padding: 30px 30px;
    }

    .hour-picker {
      display: inline-block;
      background-color: $dirtyWhite;
      margin: 30px 0 30px 0;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);

      .radio-wrapper {
        display: flex;
        margin: 5px 0 5px 0;

        p {
          padding: 9px;
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  .OnlineBookingPageFour {
    max-width: 550px;

    .form-wrapper {
      display: flex;
      flex-direction: column;
      background-color: $dirtyWhite;
      padding: 0 30px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
      margin-top: 10px;

      .MuiFormControl-root {
        margin: 20px 0;
      }
    }

    margin-bottom: 30px;

    .MuiFormHelperText-root {
      margin-top: 0;
    }
  }

  .mg {
    margin-bottom: 40px;
  }

  .OnlineBookingPageFive {
    display: inline-block;
    .check-details-wrapper {
      background-color: $dirtyWhite;
      padding: 30px 300px 30px 30px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
      margin-bottom: 30px;
      margin-top: 10px;

      label {
        color: #838383;
        font-size: 14px;
      }

      h4 {
        margin-top: 0;
      }
      .nomargin {
        margin: 0;
      }
    }
  }

  .OnlineBookingConfirmationPage {
    width: 550px;

    .content-wrapper {
      background-color: $dirtyWhite;
      padding: 30px 140px 30px 30px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
    }

    .confirm {
      font-size: 18px;
    }

    .missed-confirmation {
      font-weight: 500;
    }

    .upper {
      background-color: $lightBlue;
      margin-bottom: 30px;
      padding: 10px;
    }

    h4 {
      margin-top: 0;
    }
  }
}

@media (max-width: 1280px) {
  .OnlineBooking {
    .OnlineBookingPageOne {
      .cards {
        .MuiFormGroup-root {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .OnlineBooking {
    margin-right: 0;
    max-width: none;
    .OnlineBookingConfirmationPage {
      width: unset;
      .content-wrapper {
        padding: 20px;
      }
    }

    .OnlineBookingPageThree {
      min-width: auto;
    }
  }
}

.PrivatePickersFadeTransitionGroup-root:first-child {
  order: 2;
}
.PrivatePickersFadeTransitionGroup-root:last-child {
  order: 1;
}
.MuiIconButton-root  {
  order: 3;
}
