@import "src/mixins.scss";
@import "style/variables.module";

.Complaint {
  max-width: 960px;
  margin-top: 60px;
  margin-right: 350px;

  .upper-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
  }

  #content {
    padding: 48px 48px 30px 48px;

    h4 {
      margin-top: 20px;
    }
  }

  .blog {
    h2 {
      margin: 0;
      font-size: 24px;
      text-align: left;
      font-weight: bold;
    }
  }

  h4 {
    margin: 0;
    padding: 0;
    color: #333;
    font-size: 18px;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      padding-left: 15px;
      position: relative;
      list-style: none;
      line-height: 24px;
    }

    li::before {
      content: "•";
      position: absolute;
      left: 0;
      top: 0;
      color: $primary;
    }
  }

  div:not([class]) {
    margin: 0;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
  }
  @include correctDotColor;
}

@media (max-width: 1024px) {
  .Complaint {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .Complaint {
    margin-top: 0;

    .ShrinkingHeaderWithContent {
      margin-top: 0;

      .upper-box {
        grid-template-columns: unset;
        grid-template-rows: 1fr 1fr;
        gap: 0;
      }
    }
  }
}
