@import "style/variables.module";

.ShrinkingHeaderWithContent {
  .content {
    padding-top: 10px;
    margin-top: 250px;
    border: 1px solid $lightGrey;
    border-top: none;
    background-color: $dirtyWhite;
    min-height: 700px;
  }
}

@media (max-width: 600px) {
  .ShrinkingHeaderWithContent {
    border: 1px solid $lightGrey;
    background-color: $dirtyWhite;
    margin-top: 30px;
    .content {
      margin-top: 0 !important;
      padding: 0 10px !important;
      border: none !important;
    }
  }
}
